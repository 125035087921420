import { Link } from "react-router-dom";
import logo from "assests/logo/logo.png";

const Logo = () => {
  return (
    <Link to={"/"}>
      <div className="w-40 md:w-48">
        <img src={logo} alt="" />
      </div>
    </Link>
  );
};

export default Logo;
