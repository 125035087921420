import Container from "components/Container/Container";

const CatBoxes = ({ data = [] }) => {
  return (
    <section>
      <Container>
        <div className="flex flex-col lg:flex-row gap-6 lg:gap-10 mt-10 ">
          {data?.map((slide, index) => (
            <div
              key={index}
              className="flex-1 bg-white p-6 rounded-xl font-roboto text-darkFont"
            >
              <h6 className="text-xl font-medium mb-2">{slide.title}</h6>
              <p>{slide.text}</p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default CatBoxes;
