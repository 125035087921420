import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";

import IsLoading from "components/UI/RequestHandler/IsLoading";
import IsError from "components/UI/RequestHandler/IsError";
// components
import CatContent from "./Components/CatContent";
import CatGallery from "./Components/CatGallery";
import CatBoxes from "./Components/CatBoxes";
import Boxes from "components/UI/boxes/Boxes";

import { CategoriesBySlugContext } from "Context/Categories/CategoriesBySlugContext";

// brand
import BrandSection from "pages/BrandSection/BrandSection";

const Categories = () => {
  const { slug } = useParams();

  const { fetchData, categoriesData, isLoading, error } = useContext(
    CategoriesBySlugContext
  );

  useEffect(() => {
    fetchData(slug);
  }, [slug]);
  const { title, image, text, content, gallery, boxes, brands, meta } =
    categoriesData?.category || {};

  const contentData = {
    title,
    image,
    text,
    content,
  };

  if (isLoading) return <IsLoading />;
  if (error) return <IsError />;
  if (categoriesData) {
    return (
      <main>
        <Helmet>
          <title>{meta?.title_seo}</title>
          <meta name="description" content={meta?.description_seo} />
          <meta name="keywords" content={meta?.keywords_seo} />
        </Helmet>
        <CatContent data={contentData} />
        <CatGallery data={gallery} />
        <CatBoxes data={boxes} />

        <BrandSection data={brands} isCat={true} brandTitle={"our brands"} />
        <Boxes />
      </main>
    );
  }
};

export default Categories;
