import Container from "components/Container/Container";

const CatBoxes = ({ data = [] }) => {
  return (
    <section className="mt-primary">
      <Container>
        <h3 className="text-3xl font-medium text-red">{data.title}</h3>
        <div className="flex flex-col lg:flex-row gap-6 lg:gap-10 mt-4 lg:mt-10">
          {data?.boxedData?.map(({ title, text }, index) => (
            <div
              key={index}
              className="flex-1 bg-grey p-6 rounded-xl font-roboto text-darkFont"
            >
              <h6 className="text-xl font-medium mb-2">{title}</h6>
              <p>{text}</p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default CatBoxes;
