import Container from "components/Container/Container";
import WhiteRoundedBox from "components/UI/WhiteRoundedBox";
import Title from "components/UI/Section/Title";
const CatContent = ({ data = {} }) => {
  return (
    <section className="bg-grey flex justify-center items-center pt-24 pb-16  lg:py-36 xl:py-44">
      <Container>
        <Title title={data?.title} text={data?.text} />
        <div className="mt-10">
          <WhiteRoundedBox data={data?.content} />
        </div>
      </Container>

      <p></p>
    </section>
  );
};

export default CatContent;
