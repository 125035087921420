import Container from "components/Container/Container";
// component
import VideoDisplay from "./Components/VideoDisplay";
import VideosContent from "./Components/VideosContent";

const Video = ({ data }) => {
  return (
    <section className="my-secondary lg:my-primary ">
      <Container className={"relative "}>
        <div className="flex flex-col gap-y-3 lg:flex-row lg:gap-y-0 items-center overflow-hidden ">
          <VideosContent text={data?.text} />
          <VideoDisplay video={data?.video} />
        </div>
      </Container>
    </section>
  );
};

export default Video;
