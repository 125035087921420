import videoSource from "assests/Home/videos/untitled-2_VRZGllI8.mp4";

const VideoDisplay = () => {
  return (
    <div className="w-full lg:w-[60%] lg:ml-auto z-[0] overflow-hidden ">
      <div className="rounded-[11px]  w-full min-h-[350px]">
        <video
          src={videoSource}
          autoPlay
          className="w-full rounded-[11px] min-h-[350px] object-cover lg:object-contain"
          controls={false}
        >
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default VideoDisplay;
