import Container from "components/Container/Container";

const ClientHero = ({ data }) => {
  return (
    <section className="bg-grey pt-36 pb-20 lg:py-44 ">
      <Container>
        <div className="lg:w-1/2">
          <h1 className="font-philo text-font text-4xl lg:text-5xl font-bold">
            {" "}
            {data?.title}
          </h1>
          <p className="text-lg lg:text-xl font-roboto mt-4">{data?.text}</p>
        </div>
      </Container>
    </section>
  );
};

export default ClientHero;
