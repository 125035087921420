const BgImage = ({ image }) => {
  return (
    <div
      className="absolute top-0 left-0 w-full h-full z-[10] hero-bg"
      style={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    ></div>
  );
};

export default BgImage;
