import { useState } from "react";
import BASE_URL from "./Base_URL";

const useContactUsSubmit = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);

  const submitContactForm = async (formData) => {
    setIsLoading(true);
    setIsError(null);

    const { name, email, phone, company_name, message } = formData;

    const form = new FormData();
    form.append("name", name);
    form.append("email", email);
    form.append("phone", phone);
    form.append("company_name", company_name);
    form.append("message", message);

    try {
      const response = await fetch(`${BASE_URL}/form/contact_us`, {
        method: "POST",
        body: form,
      });

      if (!response.ok) {
        return response;
      }

      setIsLoading(false);
      return response;
    } catch (err) {
      setIsError(err.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, isError, submitContactForm };
};

export default useContactUsSubmit;
