import React, { createContext, useState, useCallback } from "react";
import getData from "Helpers/GetContent";

export const FeatureBySlugContext = createContext();

export const FeatureBySLugProvider = ({ children }) => {
  const [featuresData, setFeaturesData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getFeatureBySLugData = useCallback(async (slug) => {
    try {
      setError(false);
      setIsLoading(true);
      const data = await getData(`feature/${slug}`);
      setFeaturesData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchData = useCallback(
    (slug) => {
      getFeatureBySLugData(slug);
    },
    [getFeatureBySLugData]
  );

  return (
    <FeatureBySlugContext.Provider
      value={{ fetchData, featuresData, isLoading, error }}
    >
      {children}
    </FeatureBySlugContext.Provider>
  );
};
