import Button from "components/UI/Button";
import React, { useState, useRef, useEffect } from "react";

const VideosContent = ({ text }) => {
  const [isInView, setIsInView] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      {
        threshold: 0,
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={targetRef}
      className="w-full lg:w-[55%] lg:absolute lg:top-1/2 lg:-translate-y-1/2  lg:left-0  bg-red text-white p-6 lg:p-8 rounded-2xl z-[10] overflow-hidden"
    >
      <div
        className={`transition ease-in duration-500 ${
          isInView ? "translate-y-0 opacity-1" : "translate-y-full opacity-0"
        }`}
      >
        <p className="font-roboto text-xl mb-8">{text}</p>
        <Button to={"/about"} isBrown={true}>
          About us
        </Button>
      </div>
    </div>
  );
};

export default VideosContent;
