import React from "react";

const OurAgents = ({ data }) => {
  return (
    <div className="pt-secondary lg:pt-primary">
      <h1 className="font-philo text-5xl mb-6">Our Agents</h1>

      <div className="flex items-center gap-6 lg:gap-10 flex-wrap content-center">
        {data?.map(
          (
            {
              region,
              name,
              address,
              phone,
              fax,

              email,
            },
            index
          ) => (
            <div
              key={index}
              className="w-full sm:w-[250px] lg:w-[300px] md:w-[250px] flex flex-col bg-white p-4 text-darkFont font-roboto rounded-xl"
            >
              <p className="text-xl font-bold">{region}</p>
              <span className="mt-1 font-[500]">
                <p>{name}</p>
                <p>{address}</p>
              </span>

              <span className="my-4">
                <a href={`tel:${phone}`}>Tel: {phone}</a>
                <a className="my-3 hover:underline" href={`fax:${fax}`}>
                  Fax: {fax}
                </a>
              </span>

              <a className="hover:underline" href={`mailto:${email}`}>
                Email: {email}
              </a>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default OurAgents;
