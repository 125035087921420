// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

import { useState } from "react";
import "./Slider.css";
import {
  CaretUp,
  EnvelopeSimple,
  Phone,
  MapPin,
  X,
} from "@phosphor-icons/react";
// import required modules
import { Scrollbar } from "swiper/modules";

const ScrollSlider = ({ data = [], isSmall }) => {
  const hasPopup = data?.some((slide) => slide.popup);
  const [isModal, setIsModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const iconSize = 18;

  const handleModal = (data) => {
    setIsModal(true);
    setModalData(data);
  };

  const handleModalClose = () => {
    setIsModal(false);
    setModalData(null);
  };

  return (
    <div className=" overflow-visible">
      <Swiper
        scrollbar={{
          draggable: true,
        }}
        slidesPerView={1}
        breakpoints={{
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 2.5,
          },
        }}
        spaceBetween={20}
        modules={[Scrollbar]}
        className="catswipperslider "
      >
        {data?.map((slide, index) => (
          <SwiperSlide key={index} className="">
            <div className="relative w-full h-full  ">
              <div className="absolute left-0 top-0 w-full h-full bg-[#00000020] rounded-xl"></div>

              <img
                src={slide.image}
                alt=""
                className="h-full w-full aspect-[4/2.8] object-cover rounded-xl"
              />

              {hasPopup && (
                <button
                  onClick={() => handleModal(slide.popup)}
                  className="absolute bg-gray-200 rounded-full p-1 right-4 bottom-4 z-[100] animate-pulse"
                >
                  <CaretUp size={22} color="black" />
                </button>
              )}

              {slide.text && (
                <p className="absolute left-1/2 -translate-x-1/2 text-center bottom-0 text-white font-medium text-2xl md:text-2xl xl:text-3xl w-3/4 mb-4">
                  {slide.text}
                </p>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {isModal && (
        <>
          <div
            onClick={handleModalClose}
            className="fixed left-0 top-0 w-[100vw] h-[100vh] bg-[#00000057] z-[10]"
          ></div>
          <div className="fixed bg-white top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-[1000] flex flex-col py-8 px-10 lg:py-14 lg:px-28 w-[90%] sm:w-3/4 lg:w-auto   rounded-[44px] ">
            <button
              onClick={handleModalClose}
              className="absolute right-6 top-6"
            >
              <X size={26} />
            </button>
            <p className="text-3xl mb-2">{modalData.title}</p>
            <p className="text-lg font-medium">{modalData.address}</p>
            <div className="flex flex-col gap-y-3 mt-6">
              <span className="flex items-center gap-x-3">
                <span className="bg-red p-1 rounded-full">
                  <MapPin color="white" size={iconSize} />
                </span>
                <a
                  href={modalData.locationMapUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="text-red underline"
                >
                  View Location map
                </a>
              </span>
              <span className="flex items-center gap-x-3">
                <span className="bg-red p-1 rounded-full">
                  <Phone color="white" size={iconSize} />
                </span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`tel:${modalData.phone}`}
                >
                  {modalData.phone}
                </a>
              </span>
              <span className="flex items-center gap-x-3">
                <span className="bg-red p-1 rounded-full">
                  <EnvelopeSimple color="white" size={iconSize} />
                </span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`mailto:${modalData.email}`}
                >
                  {modalData.email}
                </a>
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ScrollSlider;
