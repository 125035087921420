import ScrollSlider from "components/UI/ScrollSlider";

const BrandGallery = ({ data = [] }) => {
  return (
    <section className="mt-secondary lg:mt-primary parent">
      <ScrollSlider data={data} isSmall={true} />
    </section>
  );
};

export default BrandGallery;
