import React, { createContext, useState, useCallback } from "react";
import getData from "Helpers/GetContent";

export const CategoriesBySlugContext = createContext();

export const CategoriesBySLugProvider = ({ children }) => {
  const [categoriesData, setCategoriesData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getCategoriesBySlugData = useCallback(async (slug) => {
    try {
      setError(false);
      setIsLoading(true);
      const data = await getData("CategoriesBySlug", slug);
      setCategoriesData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchData = useCallback(
    (slug) => {
      getCategoriesBySlugData(slug);
    },
    [getCategoriesBySlugData]
  );

  return (
    <CategoriesBySlugContext.Provider
      value={{ fetchData, categoriesData, isLoading, error }}
    >
      {children}
    </CategoriesBySlugContext.Provider>
  );
};
