import WhiteRoundedBox from "components/UI/WhiteRoundedBox";
import Container from "components/Container/Container";
import React from "react";

const BrandContent = ({ data = [] }) => {
  return (
    <section className="bg-grey pt-36 pb-16 lg:py-36 xl:py-44">
      <Container>
        <WhiteRoundedBox data={data} />
      </Container>
    </section>
  );
};

export default BrandContent;
