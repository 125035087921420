import FeaturesSliderScrolling from "./Components/FeaturesSliderScrolling";
import FeatureSlider from "./Components/FeatureSlider";

const Feature = ({ data }) => {
  return (
    <section>
      <FeaturesSliderScrolling data={data} />
      <FeatureSlider data={data} />
    </section>
  );
};

export default Feature;
