import React, { createContext, useState, useCallback } from "react";
import getData from "Helpers/GetContent";

export const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
  const [clientData, setClientData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getClientData = useCallback(async () => {
    try {
      setError(false);
      setIsLoading(true);
      const data = await getData("client");
      setClientData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchData = useCallback(() => {
    getClientData();
  }, [getClientData]);

  return (
    <ClientContext.Provider value={{ fetchData, clientData, isLoading, error }}>
      {children}
    </ClientContext.Provider>
  );
};
