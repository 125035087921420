import React, { createContext, useState, useCallback } from "react";
import getData from "Helpers/GetContent";

export const contactContext = createContext();

export const ContactProvider = ({ children }) => {
  const [contactData, setContactData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getContactData = useCallback(async () => {
    try {
      setError(false);
      setIsLoading(true);
      const data = await getData("contactus");
      setContactData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchData = useCallback(() => {
    getContactData();
  }, [getContactData]);

  return (
    <contactContext.Provider
      value={{ fetchData, contactData, isLoading, error }}
    >
      {children}
    </contactContext.Provider>
  );
};
