// slider
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";

import { Link } from "react-router-dom";
// UI
import Container from "components/Container/Container";

// import "./style.css";
const LabelSlider = ({ data }) => {
  return (
    <div className="mt-secondary xl:hidden">
      <Container>
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          speed={1000}
          spaceBetween={30}
          modules={[Pagination]}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
          }}
          className="featueSlider"
        >
          {data?.map(({ title, image }, index) => (
            <SwiperSlide key={index}>
              <div
                className={`relative min-h-[400px]  overflow-hidden rounded-xl `}
              >
                <div className="absolute w-full h-full bg-[#00000032] top-0 left-0"></div>
                <img
                  src={image}
                  alt=""
                  className="rounded-xl  min-h-[400px] object-cover"
                />

                <div className="absolute left-1/2 -translate-x-1/2 bottom-[6%] w-full sm:w-3/4 mx-auto text-center ">
                  <p className="text-white text-3xl font-bold text-center mb-2">
                    {title}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </div>
  );
};

export default LabelSlider;
