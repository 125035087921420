import Button from "components/UI/Button";

const HeroContent = ({ title }) => {
  return (
    <div className="hero-content flex flex-col  justify-center relative z-[10] ss:w-3/4 xl:w-1/2 xxl:w-[60%] pt-6">
      <h1
        className="text-4xl lg:text-5xl lg:leading-snug  text-lightFont font-philo mb-6"
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <Button to={"/about"} isBrown={true}>
        Learn More
      </Button>
    </div>
  );
};

export default HeroContent;
