import { useState } from "react";
import BASE_URL from "./Base_URL";

const useFormSubmit = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const submitForm = async (formData) => {
    setLoading(true);
    setError(null);

    const { name, email, phone, company_name, message, feature } = formData;

    const form = new FormData();
    form.append("name", name);
    form.append("email", email);
    form.append("phone", phone);
    form.append("company_name", company_name);
    form.append("message", message);
    form.append("feature", feature);

    try {
      const response = await fetch(`${BASE_URL}/form/feature`, {
        method: "POST",
        body: form,
      });

      if (!response.ok) {
        return response;
      }

      setLoading(false);
      return response;
    } catch (err) {
      setError(err.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, submitForm };
};

export default useFormSubmit;
