import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import Hero from "./Sections/Hero/Hero";
import Video from "./Sections/video/Video";
import Feature from "./Sections/features/Feature";
import Gategories from "./Sections/Gategories/Gategories";
import Brand from "./Sections/brands/Brand";
import Boxes from "components/UI/boxes/Boxes";

// content
import { HomeContext } from "Context/Home/HomeContext";

// UI
import IsLoading from "components/UI/RequestHandler/IsLoading";
import IsError from "components/UI/RequestHandler/IsError";

const Home = () => {
  const { fetchData, homeData, isLoading, error } = useContext(HomeContext);

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) return <IsLoading />;
  if (error) return <IsError />;
  if (homeData) {
    const { hero, about, features, meta } = homeData?.Home || {};

    return (
      <main>
        <Helmet>
          <title>{meta?.title_seo}</title>
          <meta name="description" content={meta?.description_seo} />
          <meta name="keywords" content={meta?.keywords_seo} />
        </Helmet>
        <Hero data={hero} />
        <Video data={about} />
        <Feature data={features} />
        <Gategories />
        <Brand />
        <Boxes />
      </main>
    );
  }
};

export default Home;
