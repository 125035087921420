import Container from "components/Container/Container";
import WhiteRoundedBox from "components/UI/WhiteRoundedBox";
// content
const AboutContent = ({ data }) => {
  return (
    <section className="bg-grey py-primary">
      <Container>
        <WhiteRoundedBox data={data} />
      </Container>
    </section>
  );
};

export default AboutContent;
