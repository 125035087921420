import React from "react";
import Container from "components/Container/Container";
import GetLinks from "constant/Header/Header";
import { Link } from "react-router-dom";
import {
  InstagramLogo,
  FacebookLogo,
  LinkedinLogo,
  EnvelopeSimple,
  Phone,
} from "@phosphor-icons/react";
const Footer = () => {
  const links = GetLinks();
  const regularLinks = links.filter(({ mega }) => !mega);
  const halfLength = Math.ceil(regularLinks.length / 2);

  const firstHalfLinks = regularLinks.slice(0, halfLength);
  const secondHalfLinks = regularLinks.slice(halfLength);
  return (
    <footer className="bg-red pt-secondary">
      <Container>
        <div className="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-8 gap-14 lg:gap-24">
          <div className="sm:col-span-2 lg:col-span-3">
            <h2 className="font-roboto text-xl text-white font-medium mb-4">
              About us
            </h2>

            <span className="text-white font-roboto pr-6 block">
              <p>
                We are a team of passionate people whose goal is to improve
                everyone's life through disruptive products. We build great
                products to solve your business problems.
              </p>

              <p className="mt-4">
                {" "}
                Our products are designed for small to medium size companies
                willing to optimize their performance.
              </p>
            </span>
          </div>

          <div className="lg:col-span-3">
            <h4 className="font-roboto text-xl text-white font-medium mb-4">
              Quick Links
            </h4>
            <div className="flex flex-col xl:flex-row  gap-2 lg:justify-between">
              <ul className="flex flex-col gap-2">
                {firstHalfLinks.map(({ link, to }, index) => (
                  <li
                    className="text-[#eee] font-roboto hover:text-white hover:underline"
                    key={index}
                  >
                    <Link to={to}>{link}</Link>
                  </li>
                ))}
              </ul>

              <ul className="flex flex-col gap-2">
                {secondHalfLinks.map(({ link, to }, index) => (
                  <li
                    className="text-[#eee] font-roboto hover:text-white hover:underline"
                    key={index}
                  >
                    <Link to={to}>{link}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="lg:col-span-2 lg:mx-auto">
            <h4 className="font-roboto text-xl text-white font-medium mb-4">
              Contact us
            </h4>
            <ul className="flex flex-col gap-1">
              <li className="flex items-center gap-x-2">
                <EnvelopeSimple color={"white"} size={20} />
                <a
                  className="text-white hover:underline"
                  href="mailto:info@almakaan.com "
                >
                  info@almakaan.com
                </a>
              </li>
              <li className="flex items-center gap-x-2">
                <Phone color={"white"} size={20} />

                <a
                  className="text-white hover:underline"
                  href="tel:+97144525420"
                >
                  +971 4 4525 420
                </a>
              </li>
            </ul>

            <div className="flex items-center gap-x-3 mt-6">
              <a
                target="_blank"
                rel="noreferrer"
                className="hover:scale-[1.05] transition ease-in duration-300"
                href="https://www.instagram.com/almakaan/"
              >
                {" "}
                <InstagramLogo color={"white"} size={24} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                className="hover:scale-[1.05] transition ease-in duration-300"
                href="https://www.facebook.com/Almakaan"
              >
                <FacebookLogo color={"white"} size={24} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                className="hover:scale-[1.05] transition ease-in duration-300"
                href="https://www.linkedin.com/company/almakaan"
              >
                <LinkedinLogo color={"white"} size={24} />
              </a>
            </div>
          </div>
        </div>
      </Container>
      <div className="bg-[#782629] py-4 mt-10 text-center text-white font-roboto">
        <p>
          © 2024 Almakaan. All rights reserved. <br />
          Powered by{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.dowgroup.com/"
            className="underline"
          >
            Dow Group.
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
