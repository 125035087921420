import React, { createContext, useState, useCallback } from "react";
import getData from "Helpers/GetContent";

export const AboutContext = createContext();

export const AboutProvider = ({ children }) => {
  const [aboutData, setAboutData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getAboutData = useCallback(async () => {
    try {
      setError(false);
      setIsLoading(true);
      const data = await getData("About");
      setAboutData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchData = useCallback(() => {
    getAboutData();
  }, [getAboutData]);

  return (
    <AboutContext.Provider value={{ fetchData, aboutData, isLoading, error }}>
      {children}
    </AboutContext.Provider>
  );
};
