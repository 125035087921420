import React, { createContext, useState, useCallback } from "react";
import getData from "Helpers/GetContent";

export const CategoriesContext = createContext();

export const CategoriesProvider = ({ children }) => {
  const [categoriesData, setCategoriesData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getCategoriesData = useCallback(async () => {
    try {
      setError(false);
      setIsLoading(true);
      const data = await getData("Gatergories");
      setCategoriesData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchData = useCallback(() => {
    getCategoriesData();
  }, [getCategoriesData]);

  return (
    <CategoriesContext.Provider
      value={{ fetchData, categoriesData, isLoading, error }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};
