import React from "react";

const WhiteRoundedBox = ({ data = [] }) => {
  return (
    <div className="flex flex-col gap-y-10 lg:gap-y-16 ">
      {data?.map((slide, index) => (
        <div
          key={index}
          className="flex flex-col lg:items-center lg:even:flex-row-reverse lg:flex-row p-4 md:p-6 lg:p-8 bg-white rounded-[22px] lg:rounded-[56px] gap-6 lg:gap-6 "
        >
          <div className="flex-1 rounded-[22px]">
            <img
              src={slide?.image}
              className="image-rounded xll:aspect-[1.5/1]   "
              alt=""
            />
          </div>
          <div className="flex-1 text-darkFont font-roboto px-4">
            {slide?.logo && (
              <span className="w-36 h-36 block">
                <img
                  src={slide?.logo}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </span>
            )}
            {slide?.title && (
              <h4 className="text-darkFont font-medium text-2xl md:text-3xl mb-1 lg:mb-2">
                {slide.title}
              </h4>
            )}

            <span className="flex flex-col gap-y-3">
              <p className="xll:text-lg">{slide.text}</p>
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default WhiteRoundedBox;
