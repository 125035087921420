import React from "react";

const Input = (props) => {
  const {
    boxStyle,
    inputStyle,
    type,
    name,
    id,
    label,
    value,
    onChange = () => {},
    onBlur = () => {},
    hasError = false,
    errorMessage = "error",
  } = props;

  return (
    <span className={boxStyle}>
      <label className="mb-2 font-roboto">{label}</label>
      <input
        className={"w-full border border-grey rounded-xl p-2"}
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <p
        className={`text-[#FF0000] text-xs my-1 ${
          hasError ? " opacity-1 " : " opacity-0"
        }`}
      >
        {errorMessage}
      </p>
    </span>
  );
};

export default Input;
