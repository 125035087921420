import Container from "components/Container/Container";
// styles
import "./Hero.css";
// component
import BgImage from "./Components/BgImage";
import HeroContent from "./Components/HeroContent";

const Hero = ({ data }) => {
  return (
    <section className="hero-section relative z-[5] flex items-center">
      <Container>
        <BgImage image={data?.image} />
        <HeroContent title={data?.title} />
      </Container>
    </section>
  );
};

export default Hero;
