import { Fragment, useContext, useEffect } from "react";
import { CategoriesContext } from "Context/Categories/CategoriesContext";
import { BrandContext } from "Context/Brand/BrandContext";
import { HomeContext } from "Context/Home/HomeContext";

const GetLinks = () => {
  const {
    fetchData: categoriesFetchData,
    categoriesData,
    isLoading: categoriesIsLoading,
    error: categoriesError,
  } = useContext(CategoriesContext);
  const {
    fetchData: brandFetchData,
    brandData,
    isLoading: brandIsLoading,
    error: brandError,
  } = useContext(BrandContext);
  const {
    fetchData: homeFetchData,
    homeData,
    isLoading: homeIsLoaidng,
    error: homeIsEror,
  } = useContext(HomeContext);

  useEffect(() => {
    categoriesFetchData();
    brandFetchData();
    homeFetchData();
  }, []);

  const dynamicFeaturesLinks =
    homeData?.Home?.features?.map(({ title, slug }, index) => ({
      link: title,
      to: `feature/${slug}`,
      key: index,
    })) || [];

  const updatedNavLinks = [
    {
      link: "Home",
      to: "/",
    },
    {
      link: "About us",
      to: "/about",
    },

    ...dynamicFeaturesLinks,

    {
      link: "Categories",
      mega: true,
      data: categoriesData?.categories,
      isLoading: categoriesIsLoading,
      isError: categoriesError,
      preSlug: "/categories/",
    },
    {
      link: "Brands",
      mega: true,
      data: brandData?.brands?.brands,
      isLoading: brandIsLoading,
      isError: brandError,
      preSlug: "/brand/",
    },

    {
      link: "Client",
      to: "/client",
    },

    {
      link: "Contact us",
      to: "/contact-us",
    },
  ];

  return updatedNavLinks;
};

export default GetLinks;
