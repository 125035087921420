import React, { useEffect, useContext } from "react";

import AboutHero from "./components/AboutHero";
import AboutHeroSlider from "./components/AboutHeroSlider";
import AboutContent from "./components/AboutContent";
import AboutGallery from "./components/AboutGallery";
import Boxes from "components/UI/boxes/Boxes";
// content
import { AboutContext } from "Context/About/AboutContext";

// UI
import IsLoading from "components/UI/RequestHandler/IsLoading";
import IsError from "components/UI/RequestHandler/IsError";
import { Helmet } from "react-helmet";

const About = () => {
  const { fetchData, aboutData, isLoading, error } = useContext(AboutContext);

  useEffect(() => {
    fetchData();
  }, []);

  const { hero, content, gallery, meta } = aboutData?.aboutUs || {};

  if (isLoading) return <IsLoading />;
  if (error) return <IsError />;
  if (aboutData) {
    return (
      <main>
        <Helmet>
          <title>{meta?.title_seo}</title>
          <meta name="description" content={meta?.description_seo} />
          <meta name="keywords" content={meta?.keywords_seo} />
        </Helmet>
        <AboutHero data={hero} />
        <AboutHeroSlider data={hero} />
        <AboutContent data={content} />
        <AboutGallery data={gallery} />

        <Boxes />
      </main>
    );
  }
};

export default About;
