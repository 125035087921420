import { useParams } from "react-router-dom";
import { useEffect, useContext, Fragment } from "react";
import IsLoading from "components/UI/RequestHandler/IsLoading";
import IsError from "components/UI/RequestHandler/IsError";
import WhiteRoundedBox from "components/UI/WhiteRoundedBox";
import { FeatureBySlugContext } from "Context/Features/FeaturesContext";
import Container from "components/Container/Container";
import Title from "components/UI/Section/Title";
import Form from "form/Form";
import { Helmet } from "react-helmet";

import LabelSlider from "pages/Label/Component/LabelSlider";
import LabelBoxes from "pages/Label/Component/LabelBoxes";
import LabelScrollingSlider from "pages/Label/Component/LabelScrolling";
import Boxes from "components/UI/boxes/Boxes";

const Features = () => {
  const { slug } = useParams();

  const { fetchData, featuresData, isLoading, error } =
    useContext(FeatureBySlugContext);

  useEffect(() => {
    fetchData(slug);
  }, [slug]);

  const { title, content, boxes, slider, meta } = featuresData?.data || {};
  const isLabel = slider?.length > 0;

  if (isLoading) return <IsLoading />;
  if (error) return <IsError />;

  if (featuresData) {
    return (
      <main>
        <Helmet>
          <title>{meta?.title_seo}</title>
          <meta name="description" content={meta?.description_seo} />
          <meta name="keywords" content={meta?.keywords_seo} />
        </Helmet>
        <div className="bg-grey py-36 lg:pt-44">
          <Container>
            <Title title={title} />
            <div className="mt-6 lg:mt-10">
              <WhiteRoundedBox data={content} />
            </div>
          </Container>

          {isLabel && (
            <>
              <LabelSlider data={slider} />
              <LabelScrollingSlider data={slider} />
            </>
          )}

          {isLabel && <LabelBoxes data={boxes} />}

          {!isLabel && (
            <div className="bg-white rounded-tl-[44px] rounded-tr-[44px] flex items-center mt-primary w-[99%] mx-auto">
              <Container>
                <Form />
              </Container>
            </div>
          )}
        </div>

        <Boxes isGrey={isLabel ? false : true} />
      </main>
    );
  }
};

export default Features;
