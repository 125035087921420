import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
gsap.registerPlugin(ScrollTrigger);

const LabelScrollingSlider = ({ data }) => {
  const component = useRef();
  const slider = useRef();

  useLayoutEffect(() => {
    if (window.innerWidth > 992) {
      let ctx = gsap.context(() => {
        let panels = gsap.utils.toArray(".panel-1");

        gsap.to(panels, {
          xPercent: -105 * (panels.length - 1),

          ease: "none",
          scrollTrigger: {
            trigger: slider.current,
            pin: true,
            scrub: 0.001,
            // snap: 0.02,
            snap: 0.01,
            end: "+=8000",
          },
        });
      }, component);

      return () => ctx.revert();
    }
  });

  return (
    <div ref={component} className="hidden xl:block mt-primary  slider-parent">
      {/* <div className=""> */}
      <div className="slider-container ml-64 " ref={slider}>
        {data?.map((slide, index) =>
          index === 0 ? (
            <div
              className={`w-[90vw] panel-1 rounded-xl mr-6   h-[90%]`}
              key={index}
            >
              <div className="grid grid-cols-3 items-center gap-24 h-full  w-[90vw] bg-white px-16 xxl:px-28 rounded-[44px]">
                <div className="col-span-2 font-philo w-3/4">
                  <h1 className="text-5xl mb-4 font-philo font-bold  text-red ">
                    {slide.title}
                  </h1>
                  <p className="text-lg text-lightFont font-roboto ">
                    {slide.text}
                  </p>

                  <div className="mt-6">
                    <p className="font-philo text-3xl text-lightFont mb-2">
                      Let's Talk
                    </p>
                    <button className="border border-red uppercase text-darkFont px-8 py-2 rounded-lg transition ease-in duration-300 hover:bg-red hover:text-white">
                      <Link to="/contact-us">Send request</Link>
                    </button>
                  </div>
                </div>
                <div className="h-3/4 opacity-0">
                  <img
                    src={slide.image}
                    alt=""
                    className="w-full h-full rounded-2xl opacity-0"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div
              className={`w-[90vw] panel-1 rounded-xl mr-6   h-[90%]`}
              key={index}
            >
              <div className="grid grid-cols-2 items-center gap-10 xxl:gap-20 h-full  w-[90vw] bg-white px-16 xxl:px-28 rounded-[44px]">
                <div className="">
                  <h1 className="text-4xl xxl:text-5xl mb-4 font-philo font-bold ">
                    {slide.title}
                  </h1>
                  <p className="text-lg text-lightFont font-roboto">
                    {slide.text}
                  </p>
                </div>
                <div className="h-[55%] ">
                  <img
                    src={slide.image}
                    alt=""
                    className="w-full h-full objecy-cover image-rounded "
                  />
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default LabelScrollingSlider;
