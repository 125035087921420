import React, { createContext, useState, useCallback } from "react";
import getData from "Helpers/GetContent";

export const BrandContext = createContext();

export const BrandProvider = ({ children }) => {
  const [brandData, setBrandData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getBrandData = useCallback(async () => {
    try {
      setError(false);
      setIsLoading(true);
      const data = await getData("Brand");
      setBrandData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchData = useCallback(() => {
    getBrandData();
  }, [getBrandData]);

  return (
    <BrandContext.Provider value={{ fetchData, brandData, isLoading, error }}>
      {children}
    </BrandContext.Provider>
  );
};
