import React from "react";

const Title = ({ title, text }) => {
  return (
    <div className="md:w-3/4 lg:w-1/2">
      <h1 className="font-philo text-font text-4xl lg:text-5xl font-bold">
        {title}
      </h1>
      {text && <p className="text-xl font-roboto mt-2 ">{text}</p>}
    </div>
  );
};

export default Title;
