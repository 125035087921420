import { useState } from "react";
import Spinner from "components/UI/RequestHandler/Spinner";
// hooks
import useInput from "./Components/Hooks/user-input";
// inputs
import Input from "./Components/Inputs/Input";
// Message
import Textarea from "./Components/Inputs/Textarea";
import useFormSubmit from "Helpers/UseFormSubmit";
import useContactUsSubmit from "Helpers/UseContactSubmit";
import { useParams } from "react-router-dom";

const Form = ({ isContact }) => {
  const { slug } = useParams();
  //   hooks
  const {
    value: fullNameInput,
    isValid: fullNameIsValid,
    isTouched: fullNameIsTouched,
    HasError: fullNameHasError,
    inputChangeHandler: fullNameChangeHandler,
    inputBlurHandler: fullNameBlurHanlder,
    reset: fullNameReset,
  } = useInput((value) => /^[a-zA-Z]+\s[a-zA-Z]+$/.test(value));

  const {
    value: phoneNumberInput,
    isValid: phoneNumberIsValid,
    isTouched: phoneNumberIsTouched,
    HasError: phoneNumberHasError,
    inputChangeHandler: phoneNumberChangeHandler,
    inputBlurHandler: phoneNumberBlurHanlder,
    reset: phoneNumberReset,
  } = useInput((value) => /^\d{8,}$/.test(value));

  const {
    value: companyNameInput,
    isValid: companyNameIsValid,
    isTouched: companyNameIsTouched,
    HasError: companyNameHasError,
    inputChangeHandler: companyNameChangeHandler,
    inputBlurHandler: companyNameBlurHanlder,
    reset: companyNameReset,
  } = useInput((value) => /^\S.*$/.test(value));
  const {
    value: emailInput,
    isValid: emailIsValid,
    isTouched: emailIsTouched,
    HasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHanlder,
    reset: emailReset,
  } = useInput((value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value));

  const {
    value: messageInput,
    isValid: messageIsValid,
    isTouched: messageIsTouched,
    HasError: messageHasError,
    inputChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlurHanlder,
    reset: messageReset,
  } = useInput((value) => /^\S.*$/.test(value));

  const { loading, error, submitForm } = useFormSubmit();
  const { isLoading, isError, submitContactForm } = useContactUsSubmit();

  const [formIsValid, SetFormIsValid] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState(false);

  const clearInputs = () => {
    SetFormIsValid(false);
  };

  const emptyInputs = () => {
    fullNameReset();
    phoneNumberReset();
    companyNameReset();
    emailReset();
    messageReset();
  };

  const isValid =
    fullNameIsValid &&
    emailIsValid &&
    phoneNumberIsValid &&
    companyNameIsValid &&
    emailIsValid &&
    messageIsValid;

  const handleSubmit = async () => {
    setFormSuccess(false);
    SetFormIsValid(false);
    setFormError(false);

    if (!isValid) {
      SetFormIsValid(true);
      return;
    }

    const formData = {
      name: fullNameInput,
      email: emailInput,
      phone: phoneNumberInput,
      company_name: companyNameInput,
      message: messageInput,
      feature: slug,
    };
    const ContactformData = {
      name: fullNameInput,
      email: emailInput,
      phone: phoneNumberInput,
      company_name: companyNameInput,
      message: messageInput,
    };

    if (isContact) {
      const formDataResponse = await submitContactForm(ContactformData);

      if (formDataResponse.ok) {
        setFormSuccess(true);
        emptyInputs();
      } else {
        setFormError(true);
      }
    } else {
      const formDataResponse = await submitForm(formData);
      if (formDataResponse.ok) {
        setFormSuccess(true);
        emptyInputs();
      } else {
        setFormError(true);
      }
    }
  };

  return (
    <div className="my-primary">
      <div
        className={`mb-14 ${
          isContact ? "flex flex-col md:items-center md:text-center" : ""
        }`}
      >
        <h2 className="text-lightFont  font-semibold font-philo text-2xl md:text-3xl">
          {`${isContact ? "Contact us" : "Let's Talk"}`}
        </h2>
        <p
          className={`text-lightFont  font-philo md:text-xl ${
            isContact ? " " : ""
          }`}
        >
          send us few information and we will contact you with less than 24
          hours
        </p>
      </div>

      <div>
        {formIsValid && (
          <p className="mb-6 text-red font-roboto">
            Please make sure all field are valid!
          </p>
        )}
        {formError && (
          <p className="mb-6 text-red font-roboto">
            Failed to send message. Please try again later!
          </p>
        )}
        {formSuccess && (
          <p className="mb-6 text-green-600 font-roboto">
            We have received your message. We will get back to you soon!
          </p>
        )}
      </div>
      <form className="flex flex-col gap-1">
        <span className="flex flex-col md:flex-row gap-1 items-center gap-x-14">
          <Input
            boxStyle={"w-full"}
            type={"text"}
            id={"fullname"}
            value={fullNameInput}
            label="First Name"
            onChange={(event) => {
              fullNameChangeHandler(event);
              clearInputs();
            }}
            onBlur={fullNameBlurHanlder}
            hasError={fullNameHasError}
            errorMessage={"Please enter your full name (e.g., 'John Doe')"}
          />

          <Input
            boxStyle={"w-full"}
            type={"number"}
            id={"phoneNumber"}
            value={phoneNumberInput}
            label="Contact Number"
            onChange={(event) => {
              phoneNumberChangeHandler(event);
              clearInputs();
            }}
            onBlur={phoneNumberBlurHanlder}
            hasError={phoneNumberHasError}
            errorMessage={
              "Please enter a valid phone number with a minimum of 8 digits"
            }
          />
        </span>
        <span className="flex flex-col md:flex-row gap-1 items-center gap-x-14">
          <Input
            boxStyle={"w-full"}
            type={"text"}
            id={"companyName"}
            value={companyNameInput}
            label="Company Name"
            onChange={(event) => {
              companyNameChangeHandler(event);
              clearInputs();
            }}
            onBlur={companyNameBlurHanlder}
            hasError={companyNameHasError}
            errorMessage={"Please enter a company name"}
          />
          <Input
            boxStyle={"w-full"}
            type={"email"}
            id={"email"}
            value={emailInput}
            label="Email"
            onChange={(event) => {
              emailChangeHandler(event);
              clearInputs();
            }}
            onBlur={emailBlurHanlder}
            hasError={emailHasError}
            errorMessage={
              "Please enter a valid email address, e.g., 'user@example.com'"
            }
          />
        </span>
        <Textarea
          boxStyle={"w-full"}
          type={"text"}
          id={"message"}
          value={messageInput}
          label="Your Message"
          onChange={(event) => {
            messageChangeHandler(event);
            clearInputs();
          }}
          onBlur={messageBlurHanlder}
          hasError={messageHasError}
          errorMessage={"Please enter a message"}
        />
      </form>

      <button
        onClick={handleSubmit}
        className={`bg-red text-white font-roboto px-6 py-2 rounded-md flex items-center gap-x-2 ${
          isContact ? "w-max mx-auto flex items-center justify-center" : ""
        }`}
      >
        {loading && <Spinner />}
        {isLoading && <Spinner />}
        <p>Send Request</p>
      </button>
    </div>
  );
};

export default Form;
