import { HomeProvider } from "./Home/HomeContext";
import { AboutProvider } from "./About/AboutContext";
import { CategoriesProvider } from "./Categories/CategoriesContext";
import { CategoriesBySLugProvider } from "./Categories/CategoriesBySlugContext";
import { BrandProvider } from "./Brand/BrandContext";
import { BrandBySlugProvider } from "./Brand/BrandBySlugContext";
import { FeatureBySLugProvider } from "./Features/FeaturesContext";
import { ClientProvider } from "./Client/ClientContext";
import { ContactProvider } from "./Contact/ContactContext";

const ContextProvider = ({ children }) => {
  return (
    <HomeProvider>
      <AboutProvider>
        <CategoriesProvider>
          <CategoriesBySLugProvider>
            <BrandProvider>
              <BrandBySlugProvider>
                <FeatureBySLugProvider>
                  <ClientProvider>
                    <ContactProvider>{children}</ContactProvider>
                  </ClientProvider>
                </FeatureBySLugProvider>
              </BrandBySlugProvider>
            </BrandProvider>
          </CategoriesBySLugProvider>
        </CategoriesProvider>
      </AboutProvider>
    </HomeProvider>
  );
};

export default ContextProvider;
