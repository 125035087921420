import { List, X, CaretRight, CaretLeft } from "@phosphor-icons/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import GetLinks from "constant/Header/Header";
import Container from "components/Container/Container";
const NavigationButton = ({ onHandleNav, isOpen }) => {
  const [selectedMegaIndex, setSelectedMegaIndex] = useState(null);
  const links = GetLinks();

  return (
    <div className="">
      <div className="text-3xl text-font">
        <button onClick={onHandleNav}>
          {isOpen ? <X color="#9F3236" /> : <List />}
        </button>
      </div>

      <div
        className={`fixed bg-black inset-0 overflow-scroll w-[100vw] min-h-min pt-40 pb-14 xl:py-32 xxl:py-44 z-[-1]  ${
          isOpen
            ? "opacity-100 translate-y-[0%] transition ease-in duration-300"
            : "opaciy-0 pointer-events-none -translate-y-[100%] invisible transition-translate ease-in duration-300"
        }`}
      >
        <Container className="h-full relative ">
          <ul
            className={`flex flex-col gap-4 text-white items-start justify-center h-full transition ease-in duration-500  ${
              isOpen
                ? "opacity-100 scale-1 transition ease-in duration-500"
                : "opacity-0 scale-0 transition-scale ease-in duration-100"
            }`}
          >
            {links.map(
              (
                { link, to, mega, data, isLoading, isError, preSlug },
                index
              ) => {
                return mega ? (
                  <button
                    onClick={() =>
                      setSelectedMegaIndex((prevIndex) =>
                        prevIndex === index ? null : index
                      )
                    }
                    className="border-b border-white w-full text-start  xl:text-lg pb-1 transition ease-in duration-300 hover:text-[#999] hover:border-[#999]"
                    key={index}
                  >
                    <span className="flex items-center gap-x-2">
                      {link}

                      <CaretRight size={18} weight="bold" />
                    </span>

                    {selectedMegaIndex === index && (
                      <span className="absolute inset-0 overflow-hidden w-full h-full bg-black z-[1] flex flex-col text-[#ccc] transition ease-in duration-300">
                        <div className="mb-10 flex items-center justify-between ">
                          <p className="text-3xl font-semibold">Our Brands</p>

                          <span className="flex items-center gap-x-2">
                            <CaretLeft size={18} weight="bold" />
                            <p>Back</p>
                          </span>
                        </div>

                        <div className="grid grid-cols-2 md:grid-cols-3 gap-8 lg:grid-cols-4  text-lg overflow-scroll lg:overflow-auto">
                          {data?.map(({ title, slug }, inndex) => (
                            <li key={inndex} className="underline capitalize ">
                              <Link
                                className="whitespace-nowrap"
                                onClick={onHandleNav}
                                to={`${preSlug}${slug}`}
                              >
                                {title}
                              </Link>
                            </li>
                          ))}
                        </div>
                      </span>
                    )}
                  </button>
                ) : (
                  <li
                    className="border-b border-white w-full pb-1 transition ease-in duration-300 hover:text-[#999] hover:border-[#999] capitalize xl:text-lg"
                    key={index}
                  >
                    <Link onClick={onHandleNav} to={to}>
                      {link}
                    </Link>
                  </li>
                );
              }
            )}
          </ul>
        </Container>
      </div>
    </div>
  );
};

export default NavigationButton;
