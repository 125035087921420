import LogoSlider from "./LogoSlider";

const BrandHeader = ({ data }) => {
  return (
    <div className="text-center">
      <div>
        <h1 className="font-roboto text-4xl font-bold text-darkFont mb-1">
          Our Brands
        </h1>
      </div>

      <LogoSlider data={data} />
    </div>
  );
};

export default BrandHeader;
