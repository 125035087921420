// slider
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Container from "components/Container/Container";

const AboutHeroSlider = ({ data }) => {
  return (
    <section className="lg:hidden h-[100vh]">
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        speed={1000}
        slidesPerView={1}
        spaceBetween={30}
        className="hero-slider h-[100vh]"
      >
        {data?.map(({ image, title, text }, index) => (
          <SwiperSlide key={index} className="h-[100vh]">
            <div className={`relative overflow-hidden rounded-xl h-full`}>
              <img
                src={image}
                alt=""
                className="rounded-xl h-full object-cover "
              />

              <div className="absolute  top-1/2 -translate-y-1/2 w-full sm:w-3/4 mx-auto ">
                <Container>
                  <h1 className="text-font text-2xl font-bold  mb-2">
                    {title}
                  </h1>

                  <p
                    className="font-philo text-xl text-darkFont"
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                </Container>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default AboutHeroSlider;
