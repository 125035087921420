import React, { useEffect, useContext } from "react";

import Container from "components/Container/Container";

import BrandHeader from "./Components/BrandHeader";
import BrandSlider from "./Components/BrandSlider";
import border from "assests/Home/services/section-border-removebg-preview.png";
import IsLoading from "components/UI/RequestHandler/IsLoading";
import IsError from "components/UI/RequestHandler/IsError";
import { BrandContext } from "Context/Brand/BrandContext";
const Brand = () => {
  const { fetchData, brandData, isLoading, error } = useContext(BrandContext);

  useEffect(() => {
    fetchData();
  }, []);

  const brandHeader = brandData?.brands?.brands;
  const brandSlider = brandData?.brands?.brandSlider;

  return (
    <section className="">
      <div className="relative z-[100]">
        <img src={border} alt="" className="w-full" />
      </div>
      <div className=" bg-grey -mt-4 ss:-mt-6 sm:-mt-8 md:-mt-10 lg:-mt-16 xxl:-mt-20 xxll:-mt-24 py-4 lg:py-secondary">
        <Container className={"pt-secondary"}>
          {isLoading && (
            <div className="mt-44 h-[50vh] flex items-center justify-center">
              <IsLoading />
            </div>
          )}
          {error && (
            <div className="mt-44 h-[50vh] flex items-center justify-center">
              <IsError />
            </div>
          )}
          {!isLoading && !error && (
            <>
              <BrandHeader data={brandHeader} />
              <BrandSlider data={brandSlider} />
            </>
          )}
        </Container>
      </div>
    </section>
  );
};

export default Brand;
