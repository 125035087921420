// UI
import Button from "components/UI/Button";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-creative";
// css
import "./Slider.css";
import { Pagination, EffectCreative, Autoplay } from "swiper/modules";

const BrandSlider = ({ data }) => {
  return (
    <div className="mt-14 overflow-y-visible ">
      <Swiper
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        speed={1000}
        autoplay={{ delay: 4000 }}
        modules={[Pagination, EffectCreative, Autoplay]}
        className="brandSwiper overflow-y-visible "
      >
        {data?.map(({ title, image }, index) => (
          <SwiperSlide key={index}>
            <div className="relative aspect-square md:aspect-auto ">
              <div className="absolute top-0 left-0 w-full h-full bg-[#00000020] rounded-xl"></div>
              <img
                className="rounded-xl h-[500px] w-full object-cover "
                src={image}
                alt=""
              />
              <div className="p-6 absolute left-0 top-1/2 -translate-y-1/2 lg:w-1/2   rounded-[44px]">
                <h6 className="font-roboto text-4xl mb-2 text-white ">
                  {title}
                </h6>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default BrandSlider;
