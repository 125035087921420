import { Link } from "react-router-dom";
const Button = ({ children, to, isBrown }) => {
  return (
    <Link to={to}>
      <button
        className={`px-6 py-2 text-white rounded-[99px] font-roboto transition ease-in duration-300 ${
          isBrown ? "bg-brown hover:bg-lightBrown" : "bg-red hover:bg-lightRed"
        }`}
      >
        {children}
      </button>
    </Link>
  );
};

export default Button;
