import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Fragment } from "react";

import IsLoading from "components/UI/RequestHandler/IsLoading";
import IsError from "components/UI/RequestHandler/IsError";
// UI
import Container from "components/Container/Container";
// content
import border from "assests/Home/services/section-border-removebg-preview.png";
// hooks
import { CategoriesContext } from "Context/Categories/CategoriesContext";

const Gategories = () => {
  const { fetchData, categoriesData, isLoading, error } =
    useContext(CategoriesContext);

  useEffect(() => {
    fetchData();
  }, []);

  const data = categoriesData?.categories;

  return (
    <section className="">
      {isLoading && (
        <div className="mt-44 flex items-center justify-center">
          <IsLoading />
        </div>
      )}
      {error && (
        <div className="mt-44 flex items-center justify-center">
          <IsError />
        </div>
      )}
      {!isLoading && !error && (
        <div className="flex flex-col gap-14">
          {data?.map(({ image, title, text, slug }, index) => (
            <Fragment key={index}>
              <div key={index}>
                <img src={border} alt="" className="w-full" />
              </div>
              <Link to={`categories/${slug}`}>
                <Container>
                  <div
                    key={index}
                    className="flex  flex-col lg:flex-row lg:items-center gap-y-2 lg:gap-x-20 md:w-34 mx-auto lg:w-full"
                  >
                    <div className="flex-1 ">
                      <img src={image} alt="" className="image-rounded " />
                    </div>
                    <div className="flex-1 font-roboto">
                      <h4 className="text-3xl md:text-4xl lg:text-5xl font-philo text-darkFont lg:mb-3">
                        {title}
                      </h4>
                      <p className="md:text-xl  text-lightFont">{text}</p>
                    </div>
                  </div>
                </Container>
              </Link>
            </Fragment>
          ))}
        </div>
      )}
    </section>
  );
};

export default Gategories;
