import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
// UI
import Container from "components/Container/Container";
// logi
import Logo from "./components/Logo/Logo";
import NavigationButton from "./components/NavigationButton/NavigationButton";

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleIsNavOpen = () => {
    setIsNavOpen((cur) => !cur);
  };
  return (
    <header className="relative z-[1000] border border-black">
      <div
        className={`${
          isNavOpen ? "fixed" : "absolute"
        }  left-0 top-0 w-full h-auto z-[1000]  `}
      >
        <Container>
          <div className="flex items-center justify-between  py-4">
            <Logo />
            <NavigationButton
              onHandleNav={handleIsNavOpen}
              isOpen={isNavOpen}
            />
          </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;
