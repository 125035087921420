import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import IsLoading from "components/UI/RequestHandler/IsLoading";
import IsError from "components/UI/RequestHandler/IsError";
// component
import BrandContent from "./Components/BrandContent";
import BrandDecription from "./Components/BrandDecription";
import BrandGallery from "./Components/BrandGallery";
import BrandSection from "pages/BrandSection/BrandSection";
import Boxes from "components/UI/boxes/Boxes";
import { Helmet } from "react-helmet";
import { BrandBySlugContext } from "Context/Brand/BrandBySlugContext";

const Brand = () => {
  const { slug } = useParams();
  const { fetchData, brandBySlugData, isLoading, error } =
    useContext(BrandBySlugContext);

  useEffect(() => {
    fetchData(slug);
  }, [slug]);

  const { content, description, gallery, meta } = brandBySlugData?.brand || {};

  if (isLoading) return <IsLoading />;
  if (error) return <IsError />;
  if (brandBySlugData) {
    return (
      <main>
        <Helmet>
          <title>{meta?.title_seo}</title>
          <meta name="description" content={meta?.description_seo} />
          <meta name="keywords" content={meta?.keywords_seo} />
        </Helmet>
        <BrandContent data={content} />
        <BrandDecription data={description} />
        <BrandGallery data={gallery} />
        <BrandSection brandTitle={"More from our brands"} />
        <Boxes />
      </main>
    );
  }
};

export default Brand;
