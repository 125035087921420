import { useContext, useEffect } from "react";
import { contactContext } from "Context/Contact/ContactContext";
import Container from "components/Container/Container";

import ContactBox from "./Components/ContactBox";
import OurAgents from "./Components/OurAgents";
import Form from "form/Form";
import IsLoading from "components/UI/RequestHandler/IsLoading";
import IsError from "components/UI/RequestHandler/IsError";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const { fetchData, contactData, isLoading, error } =
    useContext(contactContext);

  useEffect(() => {
    fetchData();
  }, []);
  const location = contactData?.contact?.location;
  const agent = contactData?.contact?.ourAgents;
  const meta = contactData?.contact?.meta;

  if (isLoading) return <IsLoading />;
  if (error) return <IsError />;
  if (contactData) {
    return (
      <main className="py-44 bg-grey">
        <Helmet>
          <title>{meta?.title_seo}</title>
          <meta name="description" content={meta?.description_seo} />
          <meta name="keywords" content={meta?.keywords_seo} />
        </Helmet>
        <Container>
          <ContactBox data={location} />
        </Container>
        <div className="bg-white rounded-tl-[44px] rounded-tr-[44px] flex items-center mt-primary w-[99%] mx-auto">
          <Container>
            <Form isContact={true} />
          </Container>
        </div>

        <Container>
          <OurAgents data={agent} />
        </Container>
      </main>
    );
  }
};

export default ContactUs;
