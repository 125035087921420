import { Link } from "react-router-dom";
// slider
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "./LogoSlider.css";
import "swiper/css/pagination";

const LogoSlider = ({ data }) => {
  return (
    <Swiper
      pagination={{
        clickable: true,
      }}
      slidesPerView={2}
      slidesPerGroup={2}
      spaceBetween={30}
      breakpoints={{
        578: {
          slidesPerView: 3,
          slidesPerGroup: 3, // Adjusted from 4 to 3
        },
        768: {
          slidesPerView: 4,
          slidesPerGroup: 3,
        },
        992: {
          slidesPerView: 6,
          slidesPerGroup: 6, // Adjusted to match slidesPerView
        },
      }}
      modules={[Pagination]}
      className="logoSlider"
      speed={800}
    >
      {data?.slice(0, 6)?.map(({ image, slug }, index) => (
        <SwiperSlide className="overflow-hidden mr-0" key={index}>
          <Link to={`brand/${slug}`}>
            <img
              className="object-contain aspect-[2.5/1] lg:aspect-[2/1] transition ease-in duration-300 hover:scale-[1.05]"
              src={image}
              alt=""
            />
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default LogoSlider;
