// slider
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";

import { Link } from "react-router-dom";
// UI
import Container from "components/Container/Container";

import "./style.css";
const FeatureSlider = ({ data }) => {
  return (
    <div className="lg:hidden">
      <Container>
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          speed={1000}
          spaceBetween={30}
          modules={[Pagination]}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
          }}
          className="featueSlider"
        >
          {data?.map(({ title, link, image, slug }, index) => (
            <SwiperSlide key={index}>
              <Link to={`feature/${slug}`}>
                <div
                  className={`relative overflow-hidden rounded-xl min-h-[350px]`}
                >
                  <img
                    src={image}
                    alt=""
                    className="rounded-xl  min-h-[350px] object-cover"
                  />

                  <div className="absolute left-1/2 -translate-x-1/2 bottom-[6%] w-full sm:w-3/4 mx-auto text-center">
                    <p className="text-white text-2xl font-bold text-center mb-2">
                      {title}
                    </p>

                    <p className="text-white text-sm text-center block">
                      {link}
                    </p>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </div>
  );
};

export default FeatureSlider;
