import Container from "components/Container/Container";
import React, { useState } from "react";

const ClientTable = ({ data }) => {
  const [visibleItems, setVisibleItems] = useState(10); // State to manage the number of visible items
  const [allLoaded, setAllLoaded] = useState(false); // State to track if all items are loaded

  // Function to handle loading more items
  const loadMore = () => {
    setVisibleItems((prev) => prev + 10); // Increase the visible items count by 10
    if (visibleItems + 10 >= data.length) {
      setAllLoaded(true); // If next batch will exceed or reach data length, mark all items as loaded
    }
  };

  // Slice the data array based on the number of visible items
  const visibleData = data.slice(0, visibleItems);

  return (
    <div className="mt-14 ">
      <Container>
        <div className="overflow-x-scroll lg:overflow-auto">
          <div className="min-w-[700px] flex flex-nowrap p-2 bg-gray-600 ">
            <div className="flex-1">
              <p className="text-xl text-white">Company Name</p>
            </div>
            <div className="flex-1">
              <p className="text-xl text-white">Category</p>
            </div>
          </div>
          {visibleData.map(({ key, value }, index) => (
            <div
              key={index}
              className=" min-w-[700px] flex flex-nowrap p-2 odd:bg-gray-400 bg-grey "
            >
              <div className="flex-1 col-span-3 border-r-[2px] border-gray-600 px-2 py-2">
                <p className="text-xl">{key}</p>
              </div>
              <div className="flex-1 col-span-2 px-2 py-2">
                <p className="text-xl">{value}</p>
              </div>
            </div>
          ))}
          {visibleItems < data.length &&
            !allLoaded && ( // Render the "Load More" button conditionally
              <div className="flex justify-center mt-10">
                <button
                  onClick={loadMore}
                  className="bg-red hover:bg-lightRed text-white font-bold py-3 px-5 rounded"
                >
                  Load More
                </button>
              </div>
            )}
        </div>
      </Container>
    </div>
  );
};

export default ClientTable;
