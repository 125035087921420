import { Routes, Route } from "react-router-dom";

import Header from "layout/Header/Header";
import Footer from "layout/Footer/Footer";
import { WhatsappLogo } from "@phosphor-icons/react";

// pages
import Home from "pages/Home/Home";
import Categories from "pages/Categories/Categories";
import Brand from "pages/BrandDetailed/Brand";
import About from "pages/About/About";
import Features from "pages/Features/Features";
import Client from "pages/client/Client";
import ContactUs from "pages/ContactUs/ContactUs";
import NotFound from "pages/NotFound/NotFound";
import ScrollToTop from "hooks/ScrollToTop";

import ContextProvider from "Context/ContextProvider";

export default function App() {
  return (
    <div className="App">
      <ContextProvider>
        <Header />
        <div className="fixed right-4 bottom-4 lg:right-6 lg:bottom-6 bg-[#25D366] rounded-full p-1.5 flex items-center justify-center z-[10000]">
          <a
            href="https://wa.me/+97144525420"
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsappLogo
              size={30}
              weight="thin"
              color="white"
              className="rounded-full"
            />
          </a>
        </div>
        <ScrollToTop />
        <Routes>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="categories/:slug" element={<Categories />} />
          <Route path="brand/:slug" element={<Brand />} />
          <Route path="feature/:slug" element={<Features />} />
          <Route path="client" element={<Client />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

        <Footer />
      </ContextProvider>
    </div>
  );
}
