import React from "react";
const ContactBox = ({ data }) => {
  return (
    <div className=" ">
      <h1 className="font-philo text-5xl mb-6">Contact Us</h1>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-10">
        {data?.map(
          (
            {
              region,
              city,
              address,
              phone,
              fax,
              poBoxNumber,
              poBoxLocation,
              email,
            },
            index
          ) => (
            <div
              key={index}
              className="w-full  flex flex-col bg-white p-4 font-roboto text-darkFont rounded-xl"
            >
              <p className="text-xl font-bold">{region}</p>
              <span className="mt-1 font-[500]">
                <p>{address}</p>
                <p>{city}</p>
              </span>

              <span className="flex flex-col my-4">
                <a className={"hover:underline"} href={`tel:${phone}`}>
                  Tel: {phone}
                </a>
                <a className={"hover:underline "} href={`fax:${fax}`}>
                  Fax: {fax}
                </a>
              </span>

              <span className="">
                {poBoxNumber && <p>P.O.BOX:{poBoxNumber}</p>}
                <p>{poBoxLocation}</p>
              </span>

              <a className={"hover:underline"} href={`mailto:${email}`}>
                Email: {email}
              </a>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ContactBox;
