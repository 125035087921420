import React, { useEffect, useContext } from "react";
import Container from "components/Container/Container";
import BrandFullSection from "../BrandSection/Components/BrandFullSection";
import { BrandContext } from "Context/Brand/BrandContext";
import IsLoading from "components/UI/RequestHandler/IsLoading";
import IsError from "components/UI/RequestHandler/IsError";

const BrandSection = ({ brandTitle, data, isCat }) => {
  const { fetchData, brandData, isLoading, error } = useContext(BrandContext);

  useEffect(() => {
    if (!isCat) {
      fetchData();
    }
  }, []);

  const getBrandsByTopStatus = (brandsData) => {
    const topBrands = brandsData?.filter((brand) => brand.isTopBrand === true);
    const nonTopBrands = brandsData?.filter(
      (brand) => brand.isTopBrand === false
    );

    return { topBrands, nonTopBrands };
  };

  const brandsData = isCat ? data : brandData?.brands?.brands;
  const { topBrands, nonTopBrands } = getBrandsByTopStatus(brandsData);

  return (
    <section>
      <Container>
        {isLoading && (
          <div className="mt-10 flex items-center justify-center">
            <IsLoading />
          </div>
        )}
        {error && (
          <div className="mt-10 flex items-center justify-center">
            <IsError />
          </div>
        )}

        {!isLoading && !error && (
          <>
            <BrandFullSection title={brandTitle} data={nonTopBrands} />
            <BrandFullSection
              title={"Exclusive Distributor of Top Brands"}
              data={topBrands}
            />
          </>
        )}
      </Container>
    </section>
  );
};

export default BrandSection;
