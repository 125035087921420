import { Link } from "react-router-dom";
import { useLayoutEffect, useRef } from "react";
// scrolling
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "./style.css";

gsap.registerPlugin(ScrollTrigger);
const FeaturesSliderScrolling = ({ data }) => {
  const component = useRef();
  const slider = useRef();

  useLayoutEffect(() => {
    if (window.innerWidth > 992) {
      let ctx = gsap.context(() => {
        let panels = gsap.utils.toArray(".panel");

        gsap.to(panels, {
          xPercent: -110 * (panels.length - 1),
          ease: "none",
          scrollTrigger: {
            trigger: slider.current,
            pin: true,
            scrub: 0.001,
            snap: 0.04,
            end: "+=5500",
          },
        });
      }, component);

      return () => ctx.revert();
    }
  });

  return (
    <div ref={component} className="hidden lg:block slider-parent">
      <div className="slider-container px-10 " ref={slider}>
        {data?.map(({ title, link, image, slug }, index) => (
          <Link key={index} to={`feature/${slug}`}>
            <div className={`panel relative overflow-hidden`}>
              <div className="w-[55%] absolute text-end content-box z-[10]">
                <h1 className="text-6xl xxl:text-7xl text-darkFont font-bold font-philo lg:w-3/4 xxll:w-1/2  lg:ml-auto ">
                  {title}
                </h1>
                {link && (
                  <p className="italic text-2xl text-lightFont">{link}</p>
                )}
              </div>
              <div className="w-1/2 ml-auto overflow-hidden rounded-xl relative z-[5]">
                <img
                  src={image}
                  alt=""
                  className="w-full h-full object-cover rounded-xl transition ease-in-out duration-300 hover:scale-[1.1]"
                />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default FeaturesSliderScrolling;
