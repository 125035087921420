import ScrollSlider from "components/UI/ScrollSlider";

const CatGallery = ({ data = [] }) => {
  return (
    <section className="mt-secondary lg:mt-primary parent">
      <h2 className="mb-4 lg:mb-10 text-darkFont text-2xl font-roboto font-medium">
        Our Diverse range of products include:
      </h2>

      <ScrollSlider data={data} isSmall={true} />
    </section>
  );
};

export default CatGallery;
