import React, { createContext, useState, useCallback } from "react";
import getData from "Helpers/GetContent";

export const BrandBySlugContext = createContext();

export const BrandBySlugProvider = ({ children }) => {
  const [brandBySlugData, setBrandBySlugData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getBrandBySlugData = useCallback(async (slug) => {
    try {
      setError(false);
      setIsLoading(true);
      const data = await getData("BrandBySlug", slug);
      setBrandBySlugData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchData = useCallback(
    (slug) => {
      getBrandBySlugData(slug);
    },
    [getBrandBySlugData]
  );

  return (
    <BrandBySlugContext.Provider
      value={{ fetchData, brandBySlugData, isLoading, error }}
    >
      {children}
    </BrandBySlugContext.Provider>
  );
};
