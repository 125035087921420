import Container from "components/Container/Container";

const BrandDecription = ({ data = [] }) => {
  return (
    <section className="mt-secondary lg:mt-primary">
      <Container>
        <div className="font-roboto lg:w-3/4">
          <h2 className="text-3xl mb-2 text-darkFont">{data?.[0]?.title}</h2>
          <p className="text-lightFont mb-4">{data?.[0]?.text}</p>

          <h6 className="text-darkFont font-semibold text-lg">
            {data?.[0]?.description}
          </h6>
        </div>
      </Container>
    </section>
  );
};

export default BrandDecription;
