import Container from "components/Container/Container";
import ScrollSlider from "components/UI/ScrollSlider";
const AboutGallery = ({ data }) => {
  return (
    <section className="mt-secondary lg:mt-primary ">
      <Container>
        <div className=" font-roboto lg:w-3/4 mb-6 lg:mb-10">
          <h2 className="text-3xl text-darkFont mb-2">Shops & Showrooms</h2>
          <p className="text-lightFont">
            Headquartered in the UAE, Al Makaan Has expanded its means of
            communication to have offices and showrooms in the UAE & through
            distributors and agents in China, Qatar and most of the GCC
            Countries.
          </p>
        </div>
      </Container>
      <div className="parent">
        <ScrollSlider data={data} />
      </div>
    </section>
  );
};

export default AboutGallery;
