import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet";

import { ClientContext } from "Context/Client/ClientContext";
// UI
import IsLoading from "components/UI/RequestHandler/IsLoading";
import IsError from "components/UI/RequestHandler/IsError";

// compponents
import Boxes from "components/UI/boxes/Boxes";
import ClientHero from "./Components/ClientHero";
import ClientTable from "./Components/ClientTable";

const Client = () => {
  const { fetchData, clientData, isLoading, error } = useContext(ClientContext);

  useEffect(() => {
    fetchData();
  }, []);

  const { title, text, companies, meta } = clientData?.client || {};
  const heroData = {
    title,
    text,
  };

  if (isLoading) return <IsLoading />;
  if (error) return <IsError />;
  if (clientData) {
    return (
      <main>
        <Helmet>
          <title>{meta?.title_seo}</title>
          <meta name="description" content={meta?.description_seo} />
          <meta name="keywords" content={meta?.keywords_seo} />
        </Helmet>
        <ClientHero data={heroData} />
        <ClientTable data={companies} />
        <Boxes />
      </main>
    );
  }
};

export default Client;
